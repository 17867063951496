export const initLanguageChanger = () => {
    const languageChanger = document.querySelector('.blockLangSwitcher');

    if(languageChanger){
        const languages = languageChanger.querySelectorAll('li');

        if(languages){
            languages.forEach(language => {
                language.addEventListener('click', () => {
                    resetAllLanguages(languages);
                    setLanguage(language);
                })
                if(language.classList.contains('active')){
                    setLanguage(language);
                }
            });
        }
    }
}

const setLanguage = (language) => {
    let url = new URL(window.location);
    let search_params = url.searchParams;
    let lang_code = language.getAttribute('data-target-lang');

    language.classList.add('active');
    search_params.set('post_language', lang_code);
    url.search = search_params.toString();

    window.history.pushState({}, '', url);

    const blocks = document.querySelectorAll(`section[data-block-lang=${language.getAttribute('data-target-lang')}]`);
    if(blocks){
        blocks.forEach(block => {
            block.classList.add('current-lang')
        })
    }
}

const resetAllLanguages = (languages) => {
    languages.forEach(language => {
        const blocks = document.querySelectorAll(`section[data-block-lang=${language.getAttribute('data-target-lang')}]`);
        if(blocks){
            blocks.forEach(block => {
                block.classList.remove('current-lang')
            })
        }
        language.classList.remove('active');
    })
}