import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockSlider = () => {
    document.addEventListener('DOMContentLoaded', function () {
        let sliderBlocks = document.querySelectorAll('.blockSlider');

        if (sliderBlocks) {
            sliderBlocks.forEach(sliderBlock => {
                let sliderElem = sliderBlock.querySelector('.blockSlider__sliderWrapper');

                const slider = new Splide(sliderElem, {
                    type: "slide",
                    rewind: true,
                    perPage: 3,
                    perMove: 1,
                    gap: 18,
                    arrows: true,
                    pagination: false,
                    autoplay: true,
                    interval: 3000,
                    breakpoints: {
                        992: {
                            perPage: 2
                        }
                    }
                });
                slider.on('mounted', () => {
                    if(slider.length <= slider.options.perPage){
                        slider.root.querySelector('.splide__arrows').style.display = "none";
                        slider.options.drag = false;
                        slider.refresh();
                    }
                })
                slider.mount();

                if(window.innerWidth <= 768){
                    slider.options.drag = false;
                    slider.destroy(true);
                }
            });
        }
    });
}