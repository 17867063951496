export function blockCollapse() {
  document.addEventListener('DOMContentLoaded', function () {
    let collapses = document.querySelectorAll(".blockCollapse__collapsible");

    if (collapses) {
      collapses.forEach(collapse => {
        collapse.addEventListener("click", () => {
          toggleCollapse(collapse);
        });

        if(window.location.hash === `#${collapse.getAttribute('data-id')}`){
          toggleCollapse(collapse);
        }
      });
    }
  });
}

const toggleCollapse = (elem) => {
  elem.classList.toggle("active");
  let content = elem.nextElementSibling;
  if (content.style.maxHeight) content.style.maxHeight = null;
  else content.style.maxHeight = content.scrollHeight + "px";
}