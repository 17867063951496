import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
var SimpleLightbox = require('simple-lightbox');
import 'simple-lightbox/dist/simpleLightbox.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {
    if(window.innerWidth < 600 ) {
      var secondarySlider = new Splide( '#secondary-slider', {
        fixedWidth : 50,
        fixedHeight: 50,
        // height     : 298,
        // gap        : 12,
        perPage    : 4,
        perMove    : 1,
        // direction  : 'ttb',
        rewind     : true,
        cover      : true,
        isNavigation: true,
        pagination : false,
      } );

      secondarySlider.on('mounted', ()=>{
        if(secondarySlider.length <= secondarySlider.options.perPage ) {
          secondarySlider.options = { arrows: false, pagination: false, drag: false };
          secondarySlider.destroy();
          secondarySlider.mount();
        }
      })

      secondarySlider.mount();
    } else {
      var secondarySlider = new Splide( '#secondary-slider', {
        fixedWidth : 72,
        fixedHeight: 72,
        // height     : 456,
        // gap        : 24,
        perPage    : 4,
        perMove    : 1,
        // direction  : 'ttb',
        rewind     : true,
        cover      : true,
        isNavigation: true,
        pagination : false,
      } );

      secondarySlider.on('mounted', ()=>{
        if(secondarySlider.length <= secondarySlider.options.perPage ) {
          secondarySlider.options = { arrows: false, pagination: false, drag: false };
          secondarySlider.destroy();
          secondarySlider.mount();
        }
      })

      secondarySlider.mount();
    }
   
    var primarySlider = new Splide( '#primary-slider', {
      type       : 'fade',
      // heightRatio: 1,
      pagination : false,
      arrows     : false,
      // cover      : true,
    } );
    
    primarySlider.sync( secondarySlider ).mount();

    var lightbox = new SimpleLightbox({
      elements: '.lightBoxRun'
    });
  });
}

