import axios from 'axios';

let paged = 1;
export const initEventCalendar = () => {
    const eventCalendars = document.querySelectorAll('.blockEventCalendar');

    if(eventCalendars){
        eventCalendars.forEach(calendar => {
            const loadMore = calendar.querySelector('button.load_more');

            if(loadMore){
                loadMore.addEventListener('click', () => {
                    const loading = calendar.querySelector('span.loading');
                    const list = calendar.querySelector('.blockEventCalendar__list');
                    const infoBox = calendar.querySelector('h1.no_events_found');
                    loadMore.style.display = "none";
                    loading.style.display = "block";
                    axios.get(`${ajax.url}?action=load_more_events`, {
                        params: {
                            paged: paged
                        }
                    }).then(response => {
                        loading.style.display = "none";
                        if(response.data.is_content){
                            if(infoBox) infoBox.remove();
                            list.innerHTML += response.data.html;
                            loadMore.style.display = "flex";

                            if(parseInt(response.data.paged) === paged){
                                loadMore.remove();
                                loading.remove();
                            }
                        } else {
                            loadMore.remove();
                            loading.remove();
                        }
                        paged++;
                    }).catch(error => {
                        console.error(error);
                        loadMore.remove();
                        loading.remove();
                    });
                });
            }
        });
    }
}