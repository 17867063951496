
export const initChooseAndRedirect = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const selectElement = document.querySelector('.blockChooseAndRedirect__select');
        const buttonLink = document.querySelector('.blockChooseAndRedirect__link');

        selectElement.addEventListener('change', (event) => {
            const url = event.target.value;
            buttonLink.href = url;
        });
    });
}
