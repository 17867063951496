import { navInit } from './modules/navigations';
import { homesiteSliders } from './modules/homesiteSliders';
import { productCustomGallery } from './modules/productCustomGallery';
import { productCardSlider } from './modules/productCardSlider';
import { productDescriptionScript } from './modules/productDescriptionScript';
import { customArchiveProductScripts } from './modules/customArchiveProductScripts';
import { blockCollapse } from './modules/blockCollapse';
import { heroSlider } from '../../includes/blocks/block-hero-slider/scripts';
import { initBlockSlider } from "../../includes/blocks/block-slider/script";
import { initChooseAndRedirect } from "../../includes/blocks/block-choose-and-redirect/script";
import { initEventCalendar } from "../../includes/blocks/block-events-calendar/script";
import { initNewsletter } from "../../includes/blocks/block-newsletter/script";
import { initNewsletterInfo } from "../../includes/blocks/block-info-box/script";
import { initBlogFilters } from "../../includes/components/blogFilters/script";
import { initLanguageChanger } from "../../includes/blocks/block-language-changer/script";
import { initHashListener } from "./modules/hashUrl";

if (document.querySelector('.headerDesktop')) navInit();

homesiteSliders();
productCardSlider();
initHashListener();

if (document.querySelector('.productCustomGallery')) {
  productCustomGallery();
}
if (document.querySelector('#wooProductDescription__wrapper')) {
  productDescriptionScript();
}
if (document.querySelector('.customArchiveProduct')) {
  customArchiveProductScripts();
}

if (document.querySelector('.blockCollapse')) {
  blockCollapse();
}

if (document.querySelector('.blockHeroSlider')) {
  heroSlider();
}

if (document.querySelector('.blockSlider')) {
  initBlockSlider();
}

if (document.querySelector('.blockChooseAndRedirect__select')) {
  initChooseAndRedirect();
}

if (document.querySelector('.blockEventCalendar')) {
  initEventCalendar();
}

if (document.querySelector('.blockNewsletter')) {
  initNewsletter();
}

if (document.querySelector('.blockInfoBox')) {
  initNewsletterInfo();
}

if (document.querySelector('.blogFilters')) {
  initBlogFilters();
}

if (document.querySelector('.blockLangSwitcher')) {
  initLanguageChanger();
}

if (document.querySelector('.customSearch--header')) {
  let header_search_icon = document.querySelectorAll(".header_search");
  let header_search = document.querySelector(".customSearch--header");
  let customSearch__close = document.querySelector(".customSearch__close");

  customSearch__close.addEventListener('click', (event) => {
    header_search.classList.toggle("close");
  })

  header_search_icon.forEach((element, index) => {
    element.addEventListener('click', (event) => {
      header_search.classList.toggle("close");

    })
  })
}

/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if (document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener('wpcf7submit', function (event) {

    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false);

  formCF7.addEventListener('submit', function () {
    if (formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });

}
