import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function heroSlider() {
    document.addEventListener('DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockHeroSlider__splide');

        if (blocks) {
            blocks.forEach(block => {
                new Splide(block, {
                    pagination: true,
                    arrows: false,
                    type: 'loop',
                    perPage: 1,
                    autoplay: true,
                    interval: 3000
                }).mount();
            })
        }
    })
}