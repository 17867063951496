export const navInit = () => {
  const headerDesktop = document.querySelector('.headerDesktop');

  if (headerDesktop) {
    let menu = headerDesktop.querySelector('.menu');

    if (menu) {
      const windowInnerW = window.innerWidth;
      const wrapeprWidth = 1248;
      const paddingRight = (windowInnerW - wrapeprWidth) / 2;
      let topMenu = document.querySelector('.headerDesktop__mainMenu ');
      topMenu.style.paddingRight = `${paddingRight}px`;
      topMenu.classList.remove('hidden');

      let hasChildItems = menu.querySelectorAll(':scope > li.menu-item-has-children');
      let currentItem = menu.querySelector(':scope > li.current-menu-item');
      let allItems = menu.querySelectorAll(':scope > li');
      if (currentItem) currentItem.classList.add('active');

      hasChildItems.forEach(hasChildItem => {
        let link = hasChildItem.querySelector('a');
        let submenu = hasChildItem.querySelector(':scope > .sub-menu');

        link.addEventListener('click', () => {
          if (submenu) {
            allItems.forEach(item => {
              if (item !== hasChildItem) {
                if (submenu.style.maxHeight && item.classList.contains('current-menu-item')) {
                  item.classList.add('active');
                } else {
                  item.classList.remove('active');
                }
              }
            })
            if (submenu.style.maxHeight) {
              submenu.style.maxHeight = null;
              submenu.classList.remove('opened');
              hasChildItem.classList.remove('active');
            }
            else {
              submenu.classList.add('opened');
              hasChildItem.classList.add('active');
              submenu.style.maxHeight = submenu.scrollHeight + 64 + "px";
              hasChildItems.forEach(otherHasChildItem => {
                if (otherHasChildItem === hasChildItem) return
                let submenu = otherHasChildItem.querySelector(':scope > .sub-menu');
                submenu.style.maxHeight = null;
                submenu.classList.remove('opened');
                otherHasChildItem.classList.remove('active');
              });
            }
          }
        })
      });
    }
  }

  const headerMobile = document.querySelector('.headerMobile');
  if (headerMobile) {
    const menu = headerMobile.querySelector('.headerMobile__mainMenu');
    const burger = headerMobile.querySelector('.headerMobile__hamburger button');

    if (menu) {
      const mainMenu = menu.querySelector('.menu_mobile');
      let hasChildItems = mainMenu.querySelectorAll(':scope > li.menu-item-has-children');
      let currentItem = mainMenu.querySelector(':scope > li.current-menu-item');
      let allItems = mainMenu.querySelectorAll(':scope > li');
      if (currentItem) currentItem.classList.add('active');

      hasChildItems.forEach(hasChildItem => {
        let link = hasChildItem.querySelector('a');
        let submenu = hasChildItem.querySelector(':scope > .sub-menu');

        link.addEventListener('click', () => {
          if (submenu) {
            allItems.forEach(item => {
              if (item !== hasChildItem) {
                if (submenu.style.maxHeight && item.classList.contains('current-menu-item')) {
                  item.classList.add('active');
                } else {
                  item.classList.remove('active');
                }
              }
            })
            if (submenu.style.maxHeight) {
              submenu.style.maxHeight = null;
              submenu.classList.remove('opened');
              hasChildItem.classList.remove('active');
            }
            else {
              submenu.classList.add('opened');
              hasChildItem.classList.add('active');
              submenu.style.maxHeight = submenu.scrollHeight + 64 + "px";
              hasChildItems.forEach(otherHasChildItem => {
                if (otherHasChildItem === hasChildItem) return
                let submenu = otherHasChildItem.querySelector(':scope > .sub-menu');
                submenu.style.maxHeight = null;
                submenu.classList.remove('opened');
                otherHasChildItem.classList.remove('active');
              });
            }
          }
        })
      });

      if (burger) {
        burger.addEventListener('click', () => {
          burger.classList.toggle('active');
          menu.classList.toggle('opened');
        })
      }
    }
  }

  const links = document.querySelectorAll('a[href]');
  if (links) {
    const menuMobile = headerMobile.querySelector('.headerMobile__mainMenu');
    links.forEach(link => {
      if (link.href.indexOf("#") !== -1) {
        let id = link.href.slice(link.href.indexOf("#"));
        if (id.length === 1) return;

        try {
          let elem = document.querySelector(id);
          if (elem) {
            link.addEventListener('click', e => {
              e.preventDefault();
              window.scrollTo({
                top: elem.offsetTop + 137,
                left: 0,
                behavior: 'smooth'
              });
              menuMobile.classList.remove('opened');
            });
          }
        } catch (e) { }
      }
    });
  }
}