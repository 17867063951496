import axios from "axios";

export const initNewsletter = () => {
    const blocks = document.querySelectorAll('.blockNewsletter');

    if(blocks){
        blocks.forEach(block => {
            const submit = block.querySelector('.newsletter-submit');
            const email = block.querySelector('.newsletter-email');
            const acceptances = block.querySelectorAll('.newsletter-acceptance');
            const errorsBox = block.querySelector('.blockNewsletter__form--errors');
            const loading = block.querySelector('.blockNewsletter--loading');

            submit.addEventListener('click', e => {
                e.preventDefault();
                sendNewsletter(email, acceptances, errorsBox, loading);
            });

            email.addEventListener('keypress', e => {
                if (e.key === "Enter") sendNewsletter(email, acceptances, errorsBox, loading);
            })
        });
    }
}

const sendNewsletter = (email, acceptances, errorsBox, loading) => {
    errorsBox.innerHTML = null;
    let accept = [];

    acceptances.forEach(acceptance => {
        accept.push({
            id: acceptance.getAttribute('data-accept-id'),
            is_checked: acceptance.checked
        });
    })

    if(accept){
        let data = {
            email: email.value,
            acceptances: accept
        };
        loading.classList.add('active');
        axios.get(`${ajax.url}?action=add_to_newsletter`, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                data: JSON.stringify(data)
            }
        }).then(res => {
            let data = res.data;
            loading.classList.remove('active');
            if(data.status){
                errorsBox.innerHTML += `<p class='success'>${data.code}</p>`
            } else {
                errorsBox.innerHTML += `<p class='error'>${data.code}</p>`
            }
        }).catch(error => {
            loading.classList.remove('active');
            console.error(error);
        })
    }
}