export const initBlogFilters = () => {
    const blogFilters = document.querySelector('.blogFilters');

    if(blogFilters){
        const baseBlogUrl = blogFilters.getAttribute('data-blog-url');
        const searchForm = blogFilters.querySelector('.blogFilters__searchForm');
        const categoriesList = blogFilters.querySelector('.blogFilters__categories');
        const authorFilter = blogFilters.querySelector('.blogFilters__authorFilters');
        const clearFilters = blogFilters.querySelector('a.clearFilters');
        const showFilters = blogFilters.querySelector('a.showFilters');

        if(searchForm){
            const input = searchForm.querySelector('input');
            const search = searchForm.querySelector('span');

            input.addEventListener('keypress', e => {
                if (e.key === "Enter"){
                    useFilters(baseBlogUrl, {
                        field: 'find',
                        value: input.value,
                        check: input.value.length > 0
                    });
                }
            })

            search.addEventListener('click', () => {
                useFilters(baseBlogUrl, {
                    field: 'find',
                    value: input.value,
                    check: input.value.length > 0
                });
            })
        }

        if(categoriesList){
            categoriesList.addEventListener('change', () => {
                const categories = categoriesList.querySelectorAll('.category-filter');

                if(categories){
                    let selected = [];
                    categories.forEach(category => {
                        const input = category.querySelector('input');

                        if(input.checked){
                            selected.push(input.getAttribute('data-cat'));
                        }
                    });

                    useFilters(baseBlogUrl, {
                        field: 'categories',
                        value: selected.join(','),
                        check: selected.length > 0
                    });
                }
            })
        }

        if(authorFilter){
            const select = authorFilter.querySelector('select.author-filter');

            if(select){
                select.addEventListener('change', () => {
                    useFilters(baseBlogUrl, {
                        field: 'author',
                        value: select.value,
                        check: select.value !== 'all'
                    });
                })
            }
        }

        if(clearFilters){
            clearFilters.addEventListener('click', () => {
                window.location = baseBlogUrl;
            })
        }

        if(showFilters){
            const wrapper = blogFilters.querySelector('.blogFilters__wrapper');
            const closePopup = blogFilters.querySelector('span.closeBtn');
            showFilters.addEventListener('click', () => {
                wrapper.classList.add('active-popup');
            })
            closePopup.addEventListener('click', () => {
                wrapper.classList.remove('active-popup');
            })
        }
    }
}

const useFilters = (base, config) => {
    const urlParams = new URLSearchParams(window.location.search);
    config.check ? urlParams.set(config.field, config.value) : urlParams.delete(config.field);
    window.location = urlParams.toString() ? base+'?'+urlParams : base;
}