export const initHashListener = () => {
    document.addEventListener('DOMContentLoaded', function () {
        if(window.location.hash){
            moveWindowToElement(window.location.hash);
        }

        window.addEventListener('hashchange', function () {
            if(window.location.hash){
                moveWindowToElement(window.location.hash);
            }
        });
    });
}

const moveWindowToElement = (id) => {
    let elem = document.querySelector(id);
    if(elem){
        window.scrollTo({
            top: elem.offsetTop - 137,
            left: 0,
            behavior: 'smooth'
        });
    }
}